import { useState } from "react";
import { ApolloError } from "@apollo/client";
import {
  useUserCheckUsernameMutation,
  useUserUpdateUsernameMutation,
} from "services/graphql";

interface UseSetUsernameOptions {
  onError?: (error: Error) => void;
  onSuccess?: () => void;
}

interface UseSetUsernameReturn {
  setUsername: (username: string) => Promise<void>;
  loading: boolean;
  error: ApolloError | Error | null;
  reset: () => void;
}

const Errors = {
  usernameUnavailable: "This username is not available",
  usernameInvalid: "This username is not valid",
  updateFailed: "Failed to update username",
} as const;

type ErrorType = typeof Errors[keyof typeof Errors];

export function useSetUsername(
  options: UseSetUsernameOptions = {}
): UseSetUsernameReturn {
  const [error, setError] = useState<ApolloError | Error | null>(null);
  const [checkUsername] = useUserCheckUsernameMutation();
  const [updateUsername, { loading }] = useUserUpdateUsernameMutation();

  const reset = () => setError(null);

  const setUsername = async (username: string) => {
    try {
      // First check if username is available
      const { data: checkData } = await checkUsername({
        variables: { input: { username } },
      });

      if (checkData?.userCheckUsername.exists) {
        throw new Error(Errors.usernameUnavailable);
      }

      // If available, update the username
      const { data: updateData } = await updateUsername({
        variables: { input: { username } },
      });

      if (!updateData?.userUpdateUsername.username) {
        throw new Error(Errors.updateFailed);
      }

      options.onSuccess?.();
    } catch (catchError) {
      if (catchError instanceof Error) {
        // Check if it's one of our known error messages
        const errorMessage = Object.values(Errors).includes(
          catchError.message as ErrorType
        )
          ? catchError.message
          : Errors.usernameInvalid;

        const formattedError = new Error(errorMessage);
        setError(formattedError);
        options.onError?.(formattedError);
      } else {
        const defaultError = new Error(Errors.usernameInvalid);
        setError(defaultError);
        options.onError?.(defaultError);
      }
    }
  };

  return {
    setUsername,
    loading,
    error,
    reset,
  };
}
